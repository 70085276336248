<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{'Hybrid '+$t('settings.lang_settings')}}
      </v-card-title>
      <v-divider class="ma-0"/>
      <v-card-text>
        <v-form lazy-validation ref="hybridSettings" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-switch v-model="enableHybrid" false-value="0" true-value="1" :label="$t('settings.lang_enableHybrid')" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  @focus="showTouchKeyboard"
                  :data-layout="KEYBOARD.KEYSETS.NUMERIC" v-model="host" :disabled="loading" outlined  :rules="[rules.ipAddress]" id="ipv4" aria-autocomplete="false"
                  :label="$t('generic.lang_ipAddress')"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions :class="{'d-block':$vuetify.breakpoint.xs,'text-right':true}">

        <v-spacer/>
        <v-btn color="success" :disabled="loading || !valid" :loading="loading" @click="saveData">{{
            $t('generic.lang_save')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import validation from "../../../mixins/validation/validation";
import mixin from '../../../mixins/KeyboardMixIns'
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";

export default {
  name: "HybridSettingsComponent",
  mixins:[validation,mixin],
  data() {
    return {
      loading: false,
      host: '127.0.0.1',//default 127.0.0.1
      valid:true,
      enableHybrid:0,
    }
  },
  computed:{
  },
  methods: {
    saveData() {

      if(!this.$refs.hybridSettings.validate()){
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.APPSETTINGS.HYBRIDSETTINGS.UPDATE, {
        hybridEnabled:this.enableHybrid,
        hybridIpAddress:this.host,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    getData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.APPSETTINGS.HYBRIDSETTINGS.GET, {

      }).then((res) => {
        if (res.data.status) {
          this.enableHybrid = res.data.hybridEnabled;
          this.host = res.data.hybridIpAddress;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getData();
  }


}
</script>
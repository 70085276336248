<template>
  <div>
    <page-title :icon=icon :heading="$t('settings.lang_appSettings')" :subheading="$t('settings.lang_appSettings')"></page-title>
    <div class="app-main__inner">
      <AppSettingsComponent/>
    </div>
  </div>
</template>

<script>

import PageTitle from "../../../Layout/Components/PageTitle";
import AppSettingsComponent from "../../../components/settings/appsettings/AppSettingsComponent";
export default {
  name: "AppSettings",
  components: {
    AppSettingsComponent,
    PageTitle
  },
  data: () => ({
    icon: 'pe-7s-settings icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0" cols="12" md="4" sm="12">
        <div role="tablist">
          <b-card class="mb-1" no-body>
            <b-card-header :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'p-0']" header-tag="header" role="tab">
              <v-list-item @click="globalSettings =! globalSettings" class="ma-0"
                           v-b-toggle.global-settings>
                <v-list-item-title :class="this.globalSettings? 'primary--text' : ''">
                  {{ $t("settings.lang_appSettings")}}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon v-if="!this.globalSettings">keyboard_arrow_down</v-icon>
                  <v-icon v-if="this.globalSettings">keyboard_arrow_up</v-icon>
                </v-list-item-action>
              </v-list-item>
            </b-card-header>
            <b-collapse :visible="this.globalSettings" accordion="global-settings" id="global-settings"
                        role="tabpanel">
              <b-card-body class="pa-0">
                <b-list-group flush>
                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'hybridSettings'? 'primary--text' : '']"
                                     @click="handleClick('hybridSettings')"
                                     class="items">{{ $t("settings.lang_hybrid") }}
                  </b-list-group-item>

                </b-list-group>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </v-col>

      <v-col cols="12" md="8" sm="12">

        <div v-if="currentTab === 'hybridSettings'">
          <HybridSettingsComponent/>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import Tabs from 'vue-tabs-with-active-line';
import HybridSettingsComponent from "./HybridSettingsComponent";

export default {
  components: {
    HybridSettingsComponent,
    Tabs
  },

  mixins: [],

  data() {
    return {
      currentTab: 'hybridSettings',
      ENDPOINTS,
      globalSettings: true,
      // ----------------------------------

    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },

  },
  computed: {
  },
  mounted() {

  },
}
</script>

<style scoped>
.tabs > .tabs__item, .tabs {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item {
  z-index: 1 !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

.items {
  cursor: pointer !important;
}

</style>